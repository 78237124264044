import React from 'react';
import PropTypes from 'prop-types';
import Logovid from '../images/RSHLogoVideo.mp4';

const Video = ({ showControls, source, autoPlay, playsInline, muted }) => (
  <video controls={showControls} autoPlay={autoPlay} playsInline={playsInline} muted={muted}>
    <source src={source} type="video/mp4" />
  </video>
);

Video.propTypes = {
  showControls: PropTypes.bool,
  source: PropTypes.any,
  autoPlay: PropTypes.bool,
  playsInline: PropTypes.bool,
  muted: PropTypes.bool,
};

Video.defaultProps = {
  showControls: false,
  source: Logovid,
  autoPlay: true,
  playsInline: true,
  muted: true,
}

export default Video;
