import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Imgix from 'react-imgix';
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/blur-up/ls.blur-up';
import Lazyload from 'react-lazyload';
import '../styles/components/FeaturedItem.scss';
import DefaultButton from './DefaultButton';
import Vid from '../images/RSH_Video_Final_small.mp4';
// import RSHVideo from '../images/RocketScienceVideo.mp4';
// import RSHVideo from '../images/RSHLogoVideo.mp4';
const isSafariMobile = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  const ua = window.navigator.userAgent;
  return ua.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i);
};
const FeaturedItem = ({
  featuredItem,
}) => {
  const videoRef = useRef(null);
  const [isInitial, setIsInitial] = useState(false);
  
  const [openModal, setOpenModal] = useState(false)
  const displayVideo = () => {
    setIsInitial(true);
    setOpenModal(true);
    if (videoRef.current) {
      // obtain reference to the video element
      const player = videoRef.current.children[0];

      // if the reference to video player has been obtained
      if (player) {
        // set the video attributes using javascript as per the
        // webkit Policy
        player.controls = true;
        player.playsinline = true;
        player.muted = false;
        // player.setAttribute("muted", ""); // leave no stones unturned :)
        player.autoplay = true;
        if (isSafariMobile()) {
          if (typeof player.webkitEnterFullscreen !== 'undefined') {
            player.webkitEnterFullscreen();
          }
        }
        // Let's wait for an event loop tick and be async.
        setTimeout(() => {
          // player.play() might return a promise but it's not guaranteed crossbrowser.
          const promise = player.play();
          // let's play safe to ensure that if we do have a promise
          if (promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                // if promise fails, hide the video and fallback to <img> tag
                videoRef.current.style.display = "none";
                // setShouldUseImage(true);
              });
          }
        }, 0);
      }
    }
    // if (videoRef !== null && videoRef.current !== null) {
    //   videoRef.current.play();
    // }
  }
  const getLeftContent = () => {
    if (featuredItem.left_aligned) {
      // Text is on the left
      return (
        <div className="left-aligned content-container">
          <div dangerouslySetInnerHTML={{ __html: featuredItem.content }} className="featured_content" />
          <DefaultButton extraClass="blue text-small" url={featuredItem.slug} title="LEARN MORE" />
          {featuredItem.has_video && (
            <DefaultButton
              onClick={() => displayVideo()}
              title="PLAY VIDEO"
              extraClass="transparent white-border"
            />
          )}
        </div>
      )
    } else {
      // Image on left
      return (
        <div className="left-aligned image-container">
          <Lazyload once>
            <Imgix
              className="lazyload blur-up image"
              width={626}
              height={626}
              htmlAttributes={{
                alt: 'Rocket Health Science',
                src: `${featuredItem.background_image.imgix_url}?blur=500&px=4&auto=format`,
              }}
              src={`${featuredItem.background_image.imgix_url}?auto=format`}
            />
          </Lazyload>
        </div>
      )
    }
  }
  const getRightContent = () => {
    if (!featuredItem.left_aligned) {
      // Text is on the Right
      return (
        <div className="right-aligned content-container">
          <div dangerouslySetInnerHTML={{ __html: featuredItem.content }} className="featured_content" />
          <DefaultButton extraClass="blue text-small" url={featuredItem.slug} title="LEARN MORE" />
          {featuredItem.has_video && (
            <DefaultButton
              onClick={() => displayVideo()}
              title="PLAY VIDEO"
              extraClass="transparent white-border"
            />
          )}
        </div>
      )
    } else {
      // Image on Right 
      return (
        <div className="right-aligned image-container">
          <Lazyload once>
            <Imgix
              className="lazyload blur-up image"
              width={626}
              height={626}
              htmlAttributes={{
                alt: 'Rocket Health Science',
                src: `${featuredItem.background_image.imgix_url}?blur=500&px=4&auto=format`,
              }}
              src={`${featuredItem.background_image.imgix_url}?auto=format`}
            />
          </Lazyload>
        </div>
      )
    }
  }
  const closeModal = () => {
    setOpenModal(false);
    if (videoRef.current) {
      const player = videoRef.current.children[0];
      if (player) {
        player.pause();
      }
    }
  }
  useEffect(() => {
    if (videoRef.current) {
      const player = videoRef.current.children[0];
      if (player) {
        player.pause();
      }
    }
    return () => {
      if (videoRef.current) {
        const player = videoRef.current.children[0];
        if (player) {
          player.pause();
          player.src = '';
        }
      }
    }
  }, [])
  return (
    <div className={`featured-item__container ${!featuredItem.left_aligned ? 'right-align' : ''}`} style={{ backgroundColor: `${featuredItem.background_color_hex}`}}>
      {getLeftContent()}
      {getRightContent()}
      <section id="video-modal" className={classNames(["modal-bg", openModal && "modal-open", !openModal && "modal-close", !isInitial && "modal-hide"])}>
        <div id="video-sleeve">
          <div className="container">
            <div className="reel-player">
              <div className="container">
                <div className="__cov-video-container" ref={videoRef} dangerouslySetInnerHTML={{ __html: `
                  <video autoplay playsinline controls>
                    <source src="${Vid}" type="video/mp4" />
                  </video>
                `}}>
                </div>
              </div>
            </div>
            <svg onClick={() => closeModal()} height="17" viewBox="0 0 17 17" width="17" xmlns="http://www.w3.org/2000/svg" className="absolute right-0 top-0 mt-3 mr-5">
              <g fill="none" fillRule="evenodd" stroke="#dc5b39" strokeLinecap="square" strokeWidth="1.5"><path d="m2 2 13.222897 13.222897"></path><path d="m15.222897 2-13.222897 13.222897"></path></g>
            </svg>
          </div>
        </div>
      </section>
      {/* <ModalVideo autoplay url={RSHVideo} channel='custom' isOpen={openModal} onClose={() => setOpenModal(false)} /> */}
    </div>
  );
};

FeaturedItem.propTypes = {
  featuredItem: PropTypes.object,
};

export default FeaturedItem;
