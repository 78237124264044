import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import Imgix from 'react-imgix';
import DefaultButton from "../components/DefaultButton";
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/blur-up/ls.blur-up';
import '../styles/components/navigation-item.scss';

const NavigationItem = ({
  url,
  image,
  short_text,
  title,
}) => (
  <div className="story-item">
    <Imgix
      className="story-background"
      height={475}
      src={`${image}?auto=format`}
      htmlAttributes={{
        alt: `Navigation Item - ${title}`,
        src: `${image}?blur=500&px=4&auto=format`,
      }}
    />
    <div className="story__text-container">
      <h2>{title}</h2>
      <p>{short_text}</p>
    </div>
    <DefaultButton url={url} extraClass="transparent" title="Learn More" />
  </div>
);

export default NavigationItem;
