import React, { useRef, useEffect, useState } from "react"
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Imgix from 'react-imgix';
import LazyLoad from 'react-lazyload';
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/blur-up/ls.blur-up';
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../styles/pages/home.scss';
import DefaultButton from "../components/DefaultButton";
import BottomQuoteCTA from "../components/BottomQuoteCTA";
import Video from "../components/Video";
import NavigationItem from "../components/NavigationItem";
import FeaturedItem from "../components/FeaturedItem";
import useInterval from "../utils/useInterval.hook";

const HomeTemplate = ({ pageContext }) => {
  // const { content } = pageContext;
  const { meta, page_contents, og_image, page_slug } = pageContext.metadata;
  const { top_background_image } = page_contents.metadata;
  const bannerRef = useRef(null);
  const items = page_contents.metadata.banner_items;
  if (items) {
    items.concat(items);
  }
  const [currentIndex, setCurrentIndex] = useState(0);
  const [bannerItems, setBannerItems] = useState(items || [])
  useInterval(() => {
    if (bannerRef !== null && bannerRef.current) {
      if (page_contents.metadata.banner_items && page_contents.metadata.banner_items.length > 0) {
        const firstItem = bannerItems[0];
        const newItems = bannerItems;
        newItems.shift();
        newItems.push(firstItem);
        setBannerItems(newItems)
        setCurrentIndex(currentIndex + 1);
      }
    }
  }, 5000)

  
  return (
    <Layout>
      <SEO pathname={page_slug} canonical={page_slug} title={meta.meta_title} description={meta.meta_description} ogImage={og_image.imgix_url || null} />
      <div className="home__top-container">
        <div className="home__contents-container">
          <div className="home__cutoff-container">
            {top_background_image && (
              <Imgix
                className="story-background"
                height={700}
                src={`${top_background_image.imgix_url}?auto=format`}
                htmlAttributes={{
                  alt: `${meta.meta_title}`,
                  src: `${top_background_image.imgix_url}?blur=500&px=4&auto=format`,
                }}
              />
            )}
          </div>
          <div className="home__contents-lefttext">
            <div className="home__main-title" dangerouslySetInnerHTML={{ __html: page_contents.metadata.main_title }} />
            <DefaultButton title="Learn More" url="/vinex/intro/" />
          </div>
        </div>
      </div>
      {page_contents.metadata.banner_items && page_contents.metadata.banner_items.length > 0 && (
        <div ref={bannerRef} className="home__banner">
          {bannerItems.map((bannerItem, index) => (
            <div key={`banner-item__${index}`} className={classNames(["home__banner-item-container", currentIndex % index === 0 && 'animateIn' ])} dangerouslySetInnerHTML={{ __html: bannerItem.item }} />
          ))}
        </div>
      )}
      <div className="home__stories-container">
        {page_contents.navigation_items && page_contents.navigation_items.map((navItem, index) => (
          
          <NavigationItem
            key={`navigation-${index}`}
            url={navItem.metadata.page_slug}
            image={navItem.metadata.navigation_image.imgix_url}
            title={navItem.metadata.navigation_title}
            short_text={navItem.metadata.navigation_short_text}
          />
        ))}
      </div>
      <div className="home__featured-container">
        {page_contents.metadata.featured_items && page_contents.metadata.featured_items.map((featuredItem, index) => (
          <FeaturedItem featuredItem={featuredItem} key={`featured-item-${index}`} />
        ))}
      </div>
      {/* <div className="home__media-research-container">
        <div className="media-header">
          <h4>Media &amp; Resources</h4>
        </div>
      </div> */}
      {page_contents.metadata.quote && page_contents.metadata.quote.quote_text && (
        <div className="home__quote-container">
          <BottomQuoteCTA
            image={page_contents.metadata.quote.image !== null && page_contents.metadata.quote.image !== undefined ? page_contents.metadata.quote.image.imgix_url : null}
            quote={page_contents.metadata.quote.quote_text}
            author={page_contents.metadata.quote.author}
          />
        </div>
      )}
    </Layout>
  );
};

HomeTemplate.propTypes = {
  pageContext: PropTypes.any,
};

HomeTemplate.defaultProps = {
  pageContext: {},
};

export default HomeTemplate;
